'use client';

import { useState, useEffect } from 'react';
import LoginModal from '@fe-news/components/molecules/LoginModal';
import useLoginModal from '@fe-news/hooks/use-login-modal';
import useMyCollection from '@fe-news/hooks/use-my-collection';
import { styled } from '@linaria/react';
import Image from 'next/image';
import useUpdateMyCollection from '@fe-news/hooks/use-update-my-collection';
import Modal from '@fe-news/components/molecules/Modal';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { GaActionType } from '@fe-cnyes/fe-common-gtm';
import SuccessFollowPopUp from '@fe-news/components/molecules/SuccessFollowPopUp';

// type
import { MyCollectionProductType, LIMIT_NEWS_COLLECTION_COUNT } from '@fe-news/constants/my-track/type';

// utils
import { isBookmark, handleFollow } from '@fe-news/utils/member';

const Container = styled.button<{ isMobileTopBar: boolean }>`
  padding: 5.5px;
  cursor: pointer;
  border-radius: 50%;
  border: ${({ isMobileTopBar }) => (isMobileTopBar ? 'none' : '1px solid #e2e8f1')};
  display: flex;
  flex-shrink: 0;
  margin-left: ${({ isMobileTopBar }) => (isMobileTopBar ? '0' : 'auto')};
  background-color: #fff;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  .new-tip {
    position: absolute;

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      top: 0px;
      left: 24px;
    }

    @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
      top: -17px;
      left: 31px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 398px;

  @media screen and (max-width: ${SCREEN_SIZE.TABLET}px) {
    min-width: 327px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: center;
  }

  p {
    margin-top: 16px;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #383838;
    text-align: center;
  }

  button {
    margin-top: 24px;
    font-size: 14px;
    background-color: #e03f19;
    color: #fff;
    padding: 9px 14px;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
  }
`;

type CollectionSaveButtonProps = {
  newsId: number;
  isMobileTopBar?: boolean;
  gtmEvent?: GaActionType;
  isRankingList?: boolean;
  isShowNewTip?: boolean;
};

const CollectionSaveButton = ({
  newsId = 0,
  isMobileTopBar = false,
  gtmEvent,
  isRankingList = false,
  isShowNewTip = false
}: CollectionSaveButtonProps) => {
  const [isClose, setIsClose] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isLoggedIn, isCloseLoginModal, onClickLoginModalOpen, onClickLoginModalClose } = useLoginModal({
    defaultCloseLoginModal: true
  });

  const { myCollectionDataItemsIdArray, refetch, isLoading } = useMyCollection({
    productType: MyCollectionProductType.NEWS,
    isLogin: isLoggedIn,
    isRankingList
  });

  const {
    deleteMyCollection,
    postMyCollection,
    isLoading: isUpdateMyCollectionLoading
  } = useUpdateMyCollection({
    refetchMyCollection: refetch
  });

  const isBookmarked = isBookmark(myCollectionDataItemsIdArray, newsId.toString());

  const handleDelete = async () => {
    const response = await deleteMyCollection({
      productType: MyCollectionProductType.NEWS,
      data: [newsId.toString()]
    });

    return response.message ?? '';
  };

  const handlePost = async () => {
    const response = await postMyCollection({
      productType: MyCollectionProductType.NEWS,
      data: [newsId.toString()]
    });

    return response.message ?? '';
  };

  const onClickBookmarked = () => {
    handleFollow({
      isLoggedIn,
      isBookmarked,
      newsId: newsId.toString(),
      dataLength: myCollectionDataItemsIdArray?.length,
      limit: LIMIT_NEWS_COLLECTION_COUNT,
      productType: MyCollectionProductType.NEWS,
      setIsSaved,
      setIsClose,
      setIsSuccess,
      handleDelete,
      handlePost,
      onClickLoginModalOpen,
      gtmEvent
    });
  };

  const onClose = () => {
    setIsClose(true);
  };

  useEffect(() => {
    setIsSaved(isBookmarked);
  }, [isBookmarked]);

  return (
    <>
      {isSuccess && <SuccessFollowPopUp setIsSuccess={setIsSuccess} isCollection />}
      <Modal isClose={isClose} onClose={onClose}>
        <Content>
          <h3>提醒您</h3>
          <p>新聞追蹤數量30筆 已達上限</p>
          <button onClick={onClose}>返回</button>
        </Content>
      </Modal>
      <LoginModal isModalClosed={isCloseLoginModal} onClickModalClose={onClickLoginModalClose} />
      <Container
        aria-label={isSaved ? 'remove' : 'save'}
        onClick={onClickBookmarked}
        disabled={isLoading || isUpdateMyCollectionLoading}
        isMobileTopBar={isMobileTopBar}
      >
        <Image
          src={isSaved ? '/assets/icons/icon-bookmark-selected.svg' : '/assets/icons/icon-bookmark.svg'}
          alt='save'
          aria-hidden='true'
          width={20}
          height={20}
        />
        {isShowNewTip && (
          <Image className='new-tip' src='/assets/icons/icon-new-tip.png' alt='new tip' width={32} height={19} />
        )}
      </Container>
    </>
  );
};

export default CollectionSaveButton;
