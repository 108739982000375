import { useMutation } from '@tanstack/react-query';
import { deleteMyCollection, postMyCollection, putMyCollection } from '@fe-news/actions/my-track';

type UseUpdateMyCollectionProps = {
  refetchMyCollection: () => void;
};

const useUpdateMyCollection = ({ refetchMyCollection }: UseUpdateMyCollectionProps) => {
  // 更新我的收藏

  const { mutateAsync: putMyCollectionMutate, isPending: isPutMyCollectionPending } = useMutation({
    mutationFn: putMyCollection,
    onSuccess: () => {
      refetchMyCollection();
    }
  });

  // 新增我的收藏

  const { mutateAsync: postMyCollectionMutate, isPending: isPostMyCollectionPending } = useMutation({
    mutationFn: postMyCollection,
    onSuccess: () => {
      refetchMyCollection();
    }
  });

  // 刪除我的收藏

  const { mutateAsync: deleteMyCollectionMutate, isPending: isDeleteMyCollectionPending } = useMutation({
    mutationFn: deleteMyCollection,
    onSuccess: () => {
      refetchMyCollection();
    }
  });

  const isLoading = isPutMyCollectionPending || isPostMyCollectionPending || isDeleteMyCollectionPending;

  return {
    putMyCollection: putMyCollectionMutate,
    postMyCollection: postMyCollectionMutate,
    deleteMyCollection: deleteMyCollectionMutate,
    isLoading
  };
};

export default useUpdateMyCollection;
