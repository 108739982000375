'use client';

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-extraneous-dependencies */

import useClickOutside from '@fe-common-utils/libs/hooks/use-click-outside';
import { formatTime } from '@fe-common-utils/libs/time';
import { NewsCategory } from '@fe-news/constants/categories';
import { useDateContext } from '@fe-news/hooks/use-date-context';
import { styled } from '@linaria/react';
import { subYears } from 'date-fns';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import useMediaQuery from '@fe-news/hooks/use-media-query';

const DatePickerModal = dynamic(() => import('./DatePickerModal'), {
  ssr: false
});

const MILLISECONDS = 1000;
const LIMIT_YEARS = 2;
const START_DATE = 0;
const END_DATE = 1;
const TODAY = new Date();

const Container = styled.div`
  border-radius: 6px;
  border: 1px solid rgb(132 141 151 / 50%);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #848d97;
  line-height: 20px;
  cursor: pointer;
  position: relative;

  img {
    margin-right: 8px;
  }
`;

const DefaultDate = styled.div`
  color: #848d97;
  width: 170px;
  caret-color: transparent;
  white-space: nowrap;

  &:hover {
    color: #515c69;
    cursor: pointer;
  }
`;

const DatePickerContainer = styled.div<{ isMemberUse: boolean }>`
  .react-datepicker {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 9999;
    display: flex;

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      left: ${({ isMemberUse }) => (isMemberUse ? '-65px' : '0px')};
    }

    &__input-container {
      display: flex;
      align-items: center;
      line-height: 20px;
      height: 20px;
    }

    &__current-month {
      border-bottom: 1px solid #e2e8f1;
      padding-bottom: 16px;
      margin: 0 10px 16px;
      font-size: 20px;
      font-weight: normal;
      color: #383838;
    }

    &__header {
      background-color: white;
      border: none;
      padding-top: 24px;
    }

    &__navigation {
      top: 20px;
    }

    &__day,
    &__day-name {
      width: 33px;
      line-height: 33px;
      font-family:
        var(--font-lato),
        var(--font-noto-sans-tc),
        Pingfang TC,
        Microsoft Jhenghei,
        Arial,
        VideoJS,
        sans-serif !important;
    }

    &__day-name {
      font-size: 12px;
      color: #848d97;
      font-weight: bold;
    }

    &__day--selected,
    &__day--in-range,
    &__day--in-selecting-range {
      background-color: rgba(224, 63, 25, 0.1);
      color: #383838;

      :hover {
        background-color: rgba(224, 63, 25, 0.1);
        color: #383838;
      }
    }

    &__day--range-start,
    &__day--range-end {
      outline: solid 3px #e03f19;
      background-color: rgba(224, 63, 25, 0.8);
      border-radius: 100%;
      color: white;

      :hover {
        outline: solid 3px #e03f19;
        background-color: rgba(224, 63, 25, 0.8);
        border-radius: 100%;
        color: white;
      }
    }

    &__day--in-selecting-range {
      background-color: rgba(224, 63, 25, 0.1) !important;
      color: #383838 !important;
    }

    &__day--selecting-range-start,
    &__day--selecting-range-end {
      outline: solid 3px #e03f19;
      background-color: rgba(224, 63, 25, 0.8) !important;
      border-radius: 100%;
      color: white !important;

      :hover {
        outline: solid 3px #e03f19;
        background-color: rgba(224, 63, 25, 0.8) !important;
        border-radius: 100%;
      }
    }

    &__day--outside-month {
      outline: 0;
    }
  }

  &:hover {
    color: #515c69;
    cursor: pointer;
  }
`;

type DatePickerProps = {
  startAt?: MaybeUndefined<number>;
  endAt?: MaybeUndefined<number>;
  category?: NewsCategory;
  onDateChange?: (dates: [number, number]) => void;
  isMemberUse?: boolean;
};

const DatePicker: React.FC<DatePickerProps> = ({ startAt, endAt, onDateChange, isMemberUse = false }) => {
  const { dates, setDates } = useDateContext();

  const searchDate = useMemo(
    () => [startAt ? new Date(startAt) : dates[START_DATE], endAt ? new Date(endAt) : dates[END_DATE]],
    [dates, startAt, endAt]
  );
  const [selectedDates, setSelectedDates] = useState<Date[]>(searchDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isDesktop = useMediaQuery(`(min-width:${SCREEN_SIZE.DESKTOP}px)`, true);
  const showMonths = isMemberUse ? (isDesktop ? 2 : 1) : 2;

  useEffect(() => {
    if (selectedDates[END_DATE]) {
      setDates && setDates(selectedDates);
    }
  }, [selectedDates, setDates]);

  const onChange = (newDates: [Date, Date]) => {
    setSelectedDates(newDates);

    // 選擇結束日期後關閉modal
    if (newDates[END_DATE]) {
      setIsOpen(false);

      onDateChange && onDateChange?.([newDates[START_DATE].getTime(), newDates[END_DATE].getTime()]);
    }
  };

  const onOpenDatePicker = () => {
    setIsOpen(v => !v);
  };

  const containerReference = useRef(null);

  const onClickOutside = () => setIsOpen(false);

  useClickOutside(containerReference, onClickOutside, isOpen);

  // 顯示選擇的日期區間範圍資訊
  const start = useMemo(
    () => formatTime(selectedDates[START_DATE]?.getTime() / MILLISECONDS, 'YYYYMMDD', '/'),
    [selectedDates]
  );
  const end = useMemo(
    () => formatTime(selectedDates[END_DATE]?.getTime() / MILLISECONDS, 'YYYYMMDD', '/'),
    [selectedDates]
  );

  return (
    <Container ref={containerReference}>
      <Image src='/assets/icons/date/calendar.svg' width={16} height={16} alt='calendar-icon' />
      <DefaultDate onClick={onOpenDatePicker}>{`${start} - ${end}`}</DefaultDate>

      {isOpen && (
        <DatePickerContainer isMemberUse={isMemberUse}>
          <DatePickerModal
            selected={selectedDates[START_DATE]}
            onChange={onChange}
            startDate={selectedDates[START_DATE]}
            endDate={selectedDates[END_DATE]}
            selectsRange
            monthsShown={showMonths}
            maxDate={TODAY}
            minDate={subYears(TODAY, LIMIT_YEARS)}
            locale='zh-TW'
            dateFormat='yyyy/MM/dd'
            calendarStartDay={0}
            inline
          />
        </DatePickerContainer>
      )}
    </Container>
  );
};

export default DatePicker;
