'use client';

import { useEffect, useState } from 'react';
import useAuth from '@fe-news/hooks/auth';

type UseLoginModal = {
  isLoggedIn: boolean;
  isCloseLoginModal: boolean;
  onClickLoginModalOpen: () => void;
  onClickLoginModalClose: () => void;
};

type UseLoginModalProps = {
  defaultCloseLoginModal?: boolean;
};

const useLoginModal = ({ defaultCloseLoginModal = false }: UseLoginModalProps): UseLoginModal => {
  const [status] = useAuth();
  const isLoggedIn = status === 'login';
  const [isCloseLoginModal, setIsCloseLoginModal] = useState(defaultCloseLoginModal);

  const onClickLoginModalOpen = () => {
    setIsCloseLoginModal(false);
  };

  const onClickLoginModalClose = () => {
    setIsCloseLoginModal(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsCloseLoginModal(true);
    }
  }, [isLoggedIn]);

  return { isLoggedIn, isCloseLoginModal, onClickLoginModalOpen, onClickLoginModalClose };
};

export default useLoginModal;
