'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const MAX_RETRIES = 1;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // automatically requests fresh data in the background if user leaves the app and returns to stale data.
      refetchOnWindowFocus: false,
      // if true, refetch on reconnect if the data is stale.
      refetchOnReconnect: false,
      // the time in milliseconds after data is considered stale. Defaults to 0.
      // staleTime: 5*60*1000,
      // if true, failed queries will retry infinitely
      retry: MAX_RETRIES
    }
  }
});

interface QueryProviderProps {
  children: React.ReactNode;
  isHideDevtools?: boolean;
  dehydratedState?: unknown;
}

export default function QueryProviderClient({ children, isHideDevtools = false }: QueryProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!isHideDevtools && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}
