'use client';

import { styled } from '@linaria/react';

type CalendarProps = {
  timestamp: number;
  marginRight?: number;
};

const Container = styled.div<{ marginRight?: number }>`
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
  background-color: #fff;
  font-size: 16px;
  color: #515c69;
  position: relative;
  width: 26px;
  height: 26px;
  padding-top: 5px;
  text-align: center;
  margin-right: ${({ marginRight }) => marginRight || 0}px;

  &::before {
    content: '';
    width: 12px;
    height: 2px;
    background-color: #e03f19;
    position: absolute;
    top: 4px;
    left: 7px;
  }
`;

const CalendarIcon = ({ timestamp, marginRight }: CalendarProps) => {
  const day = new Date(timestamp).getDate();

  return <Container marginRight={marginRight}>{day}</Container>;
};

export default CalendarIcon;
