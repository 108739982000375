import { useQuery } from '@tanstack/react-query';
import { MyCollectionProductType, ReturnMyCollectionType } from '@fe-news/constants/my-track/type';
import { fetchMyCollection } from '@fe-news/actions/my-track';

// type
import type { Response } from '@fe-news/constants/api';

type UseMyCollectionProps = {
  productType?: MyCollectionProductType;
  groupId?: number;
  isLogin?: boolean;
  isRankingList?: boolean;
};

const useMyCollection = ({ productType, groupId, isLogin = false, isRankingList = false }: UseMyCollectionProps) => {
  // 取得 我的收藏列表

  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      'my-collection',
      `productType: ${productType}`,
      `groupId: ${groupId}`,
      `isRankingList: ${isRankingList}`
    ],
    queryFn: () => fetchMyCollection({ productType, groupId }),
    select: (response: Response<ReturnMyCollectionType>) => {
      const items = response?.data?.items ?? [];
      return items.length > 0 ? items.map(item => String(item.productId)) : [];
    },
    enabled: isLogin,
    staleTime: 1000 * 60 * 60 * 1,
    gcTime: 1000 * 60 * 30
  });

  return {
    refetch: async () => {
      await refetch();
    },
    isLoading,
    myCollectionDataItemsIdArray: data ?? []
  };
};

export default useMyCollection;
