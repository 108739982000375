/* eslint-disable react/boolean-prop-naming */
import { styled } from '@linaria/react';

const Button = styled.button<{ isHollowType: boolean }>`
  padding: 8px 13.5px;
  border-radius: 6px;
  background-color: ${({ isHollowType }) => (isHollowType ? 'transparent' : '#e03f19')};
  min-width: 60px;
  border: solid 1px #e03f19;
  padding: 11px 0px;
  width: 100%;

  &:hover {
    background-color: ${({ isHollowType }) => (isHollowType ? '#fff3f0' : '#f04e28')};
    cursor: pointer;
  }

  &:disabled {
    background-color: ${({ isHollowType }) => (isHollowType ? 'transparent' : '#eef1f6')};
    border: ${({ isHollowType }) => (isHollowType ? 'solid 1px #848d97' : 'solid 1px #eef1f6')};
    cursor: not-allowed;
  }
`;

type MemberButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isHollowType?: boolean;
  style?: React.CSSProperties;
};

const MemberButton = ({ children, onClick, disabled, isHollowType = false, style }: MemberButtonProps) => {
  return (
    <Button onClick={onClick && onClick} disabled={disabled} isHollowType={isHollowType} type='button' style={style}>
      {children}
    </Button>
  );
};

export default MemberButton;
