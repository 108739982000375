'use client';

import { formatTime } from '@fe-common-utils/libs/time';
import CardSize from '@fe-news/constants/size/card';
import { styled } from '@linaria/react';
import { useEffect, type CSSProperties } from 'react';

type DateCardProps = {
  datetime?: number;
  index?: number;
  style?: MaybeUndefined<CSSProperties>;
  isShowToday?: boolean;
  setSize?: (index: number, size: number) => void;
  isPopTopic?: boolean;
  isDesktop?: boolean;
};

const View = styled.div`
  color: #848d97;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 0;
`;

export const DateCard = ({ datetime, style }: { datetime: number; style: React.CSSProperties }) => {
  const dateString = (datetime && formatTime(datetime, 'YYYYMMDD')) || '';

  return (
    <View className='date-card-container' style={style}>
      {dateString}
    </View>
  );
};

const InfiniteDateCard = ({
  datetime,
  index = 0,
  style,
  setSize,
  isPopTopic = false,
  isDesktop = false
}: DateCardProps) => {
  const dateString = (datetime && formatTime(datetime, 'YYYYMMDD')) || '';

  useEffect(() => {
    const size = isPopTopic
      ? isDesktop
        ? CardSize.DesktopPopTopicDate
        : CardSize.MobilePopTopicDate
      : CardSize.DesktopDate;

    setSize?.(index, size);
  }, [index, setSize, isPopTopic, isDesktop]);

  return (
    <div key={`date-card-${index}`} style={style} className='date-list-item'>
      <View className='date-card-container'>{dateString}</View>
    </div>
  );
};

export default InfiniteDateCard;
