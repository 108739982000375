'use client';

import CalendarIcon from '@fe-common-ui/Icons/Calendar/Calendar';
import { formatTime, isToday } from '@fe-common-utils/libs/time';
import { useDateContext } from '@fe-news/hooks/use-date-context';
import { styled } from '@linaria/react';
import { memo } from 'react';

const MILLISECONDS = 1000;

const View = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Today = styled.div<{ fontWeight?: number }>`
  font-size: 20px;
  color: #515c69;
  line-height: 1.2;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;

type CalendarProps = {
  timestamp?: number;
  fontWeight?: number;
};

const Calendar: React.FC<CalendarProps> = ({ timestamp, fontWeight }) => {
  const { dates } = useDateContext();
  const endDate = timestamp || dates?.[1]?.getTime() || Date.now();
  const isDateToday = isToday(endDate);
  const title = isDateToday ? '今天' : formatTime(endDate / MILLISECONDS, 'YYYYMMDD');

  return (
    <View>
      {isDateToday && <CalendarIcon timestamp={endDate} marginRight={8} />}
      <Today fontWeight={fontWeight}>{title}</Today>
    </View>
  );
};

export default memo(Calendar);
