'use client';

import DatePicker from '@fe-news/components/DatePicker/DatePicker';
import { NewsCategory } from '@fe-news/constants/categories';
import { styled } from '@linaria/react';
import Calendar from '@fe-news/components/atoms/Calendar/Calendar';
import HeadlineFilter from './HeadlineFilter';

const Container = styled.section`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Divide = styled.div`
  background-color: #eaeef5;
  width: 2px;
  height: 20px;
  margin: 0 11.5px;
`;

const NewsFilter = ({ category }: { category: NewsCategory }) => {
  return (
    <Container>
      <Calendar />
      <DatePicker category={category} />
      <Divide />
      <HeadlineFilter category={category} />
    </Container>
  );
};

export default NewsFilter;
