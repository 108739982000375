'use client';

import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@linaria/react';
import Image from 'next/image';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(22, 23, 32, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div<{ maxWidth?: string }>`
  padding: 24px 12px 16px 12px;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  width: fit-content;
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`;

type ModalProps = {
  children: React.ReactNode;
  isClose: boolean;
  onClose: () => void;
  closeButtonStyle?: React.CSSProperties;
  modalWrapperStyle?: React.CSSProperties;
  modalContainerStyle?: React.CSSProperties;
};

const Modal: React.FC<ModalProps> = ({
  children,
  isClose,
  onClose,
  closeButtonStyle,
  modalWrapperStyle,
  modalContainerStyle
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const { top, right } = closeButtonStyle || {};

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted) {
      document.body.style.overflowY = isClose ? 'auto' : 'hidden';
    }
  }, [isClose, isMounted]);

  if (isClose || !isMounted) return null;

  return createPortal(
    <ModalWrapper style={modalWrapperStyle}>
      <ModalContainer style={modalContainerStyle}>
        <CloseButton onClick={onClose} style={{ top, right }}>
          <Image src='/assets/icons/close/modal-icon-close.svg' alt='close-icon' width={20} height={20} />
        </CloseButton>
        {children}
      </ModalContainer>
    </ModalWrapper>,
    document.body
  );
};

export default Modal;
