import { Dispatch, SetStateAction } from 'react';
import { sendGTM, GaActionType } from '@fe-cnyes/fe-common-gtm';

// type
import { MyCollectionProductType } from '@fe-news/constants/my-track/type';

export const isBookmark = (myCollectionDataItemsIdArray: string[], id: string): boolean => {
  return myCollectionDataItemsIdArray?.includes(id);
};

export const handleFollow = async ({
  isLoggedIn,
  isBookmarked,
  newsId,
  dataLength,
  limit,
  productType,
  setIsSaved,
  setIsClose,
  setIsSuccess,
  handleDelete,
  handlePost,
  onClickLoginModalOpen,
  gtmEvent
}: {
  isLoggedIn: boolean;
  isBookmarked: boolean;
  newsId: string;
  dataLength: number;
  limit: number;
  productType: MyCollectionProductType;
  setIsSaved: Dispatch<SetStateAction<boolean>>;
  setIsClose: Dispatch<SetStateAction<boolean>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => Promise<string>;
  handlePost: () => Promise<string>;
  onClickLoginModalOpen: () => void;
  gtmEvent?: GaActionType;
}) => {
  if (isLoggedIn) {
    try {
      if (isBookmarked) {
        setIsSaved(false);
        const responseMessage = await handleDelete();
        setIsSaved(!(responseMessage === '成功'));
      } else {
        if (dataLength >= limit) {
          setIsClose(false);
          return;
        }
        setIsSaved(true);
        const responseMessage = await handlePost();
        if (responseMessage !== '成功') {
          throw new TypeError(JSON.stringify({ message: responseMessage }));
        }
        setIsSaved(responseMessage === '成功');
        setIsSuccess(true);
        gtmEvent && sendGTM(gtmEvent);
      }
    } catch (error) {
      if (isBookmarked) {
        setIsSaved(true);
        console.error(`[ACTION ERROR]: delete ${productType} error : ${newsId}`, error);
      } else {
        if (error instanceof Error && JSON.parse(error.message).message === '超出資料上限') {
          setIsClose(false);
        }
        setIsSaved(false);
        setIsSuccess(false);
        console.error(`[ACTION ERROR]: add ${productType} error : ${newsId}`, error);
      }
    }
  } else {
    onClickLoginModalOpen();
  }
};
