'use client';

import { styled } from '@linaria/react';
import Image from 'next/image';
import Modal from '@fe-news/components/molecules/Modal';
import { login } from '@fe-news/hooks/auth';
import MemberButton from '@fe-news/components/atoms/Member/MemberButton';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 24px;
  letter-spacing: 0.5px;
  min-width: 398px;

  @media screen and (max-width: ${SCREEN_SIZE.TABLET}px) {
    min-width: 327px;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #383838;
  }
`;

const ButtonText = styled.span`
  font-size: 14px;
  color: #fff;
`;

type LoginModalProps = {
  onClickModalClose: () => void;
  isModalClosed: boolean;
};

const LoginModal = ({ onClickModalClose, isModalClosed }: LoginModalProps) => {
  const onClickLogin = () => {
    onClickModalClose();
    login();
  };

  return (
    <Modal isClose={isModalClosed} onClose={onClickModalClose}>
      <Content>
        <h3>登入會員</h3>
        <Image src='/assets/icons/empty/login-empty.svg' alt='login-empty-icon' width={200} height={143} />
        <p>請先登入，以使用完整功能</p>
        <MemberButton onClick={onClickLogin} aria-label='登入鉅亨會員'>
          <ButtonText>登入鉅亨會員</ButtonText>
        </MemberButton>
      </Content>
    </Modal>
  );
};

export default LoginModal;
