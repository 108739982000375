/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@linaria/react';
import cx from 'classnames';

type TabProps = {
  active: number;
  onClick: (n: number) => void;
  children: React.ReactNode[];
};

const Contaier = styled.span`
  background-color: #f6f8fc;
  border-radius: 10px;
  padding: 3px;
`;

const LabelButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #515c69;
  padding: 4px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 30px;

  &.active {
    background: white;
    color: #e03f19;
    box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.1);
    border: solid 1px rgba(0, 65, 143, 0.1);
  }
`;

export const Switch = ({ active = 0, onClick, children }: TabProps) => {
  const handleClick = (newValue: number) => () => {
    onClick(newValue);
  };

  return (
    <Contaier>
      {children?.map((element: React.ReactNode, index: number) => (
        <LabelButton
          key={`tab-${index}`}
          className={cx({ active: active === index })}
          onClick={handleClick(index)}
        >
          {element}
        </LabelButton>
      ))}
    </Contaier>
  );
};

export default Switch;
