'use client';

import { NewsCategory } from '@fe-news/constants/categories';
import { subDays, subYears, subMonths } from 'date-fns';
import { createContext, useMemo, useState } from 'react';

type DateContextValue = {
  dates: Date[];
  setDates?: React.Dispatch<React.SetStateAction<Date[]>>;
};

export const DateContext = createContext<DateContextValue>({
  dates: []
});

export type DateProviderPros = {
  category?: NewsCategory;
  children: React.ReactNode;
};

const ONE_YEAR = 1;
const TEN_DAYS = 10;
const THREE_MONTHS = 3;
const TODAY = new Date();

export default function DateProvider({ category, children }: DateProviderPros) {
  // 新聞分類「區塊鏈>新手村」文章，日期區間拉長至"一年間"，此分類的文章產出速度比較慢，如果日期區間拉太短，文章列表會常常都是空白，故希望將日期區間拉至＂一年＂
  const defaultDates = useMemo(
    () =>
      category === NewsCategory.BC_TUTORIAL
        ? [subYears(TODAY, ONE_YEAR), TODAY]
        : category === NewsCategory.MY_TAG || category === NewsCategory.PORTFOLIOS
          ? [subMonths(TODAY, THREE_MONTHS), TODAY]
          : [subDays(TODAY, TEN_DAYS), TODAY],
    [category]
  );

  const [currentDate, setCurrentDate] = useState<Date[]>(defaultDates);
  const value = useMemo(
    () => ({
      dates: currentDate,
      setDates: setCurrentDate
    }),
    [currentDate]
  );

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
}
