'use client';

import { DateContext } from '@fe-news/context/date-provider';
import { useContext } from 'react';

export const useDateContext = () => {
  const dateContext = useContext(DateContext);

  return dateContext;
};
