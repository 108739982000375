/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
'use client';

import { styled } from '@linaria/react';
import cx from 'classnames';

type ItemValue = number | string;

const Tabs = styled.ul`
  display: flex;
  align-items: center;
`;
const Tab = styled.li`
  font-size: 16px;
  letter-spacing: 0.67px;
  line-height: 22px;
  color: #848d97;

  :not(:last-child)::after {
    content: '';
    height: 16px;
    width: 2px;
    background-color: #e2e8f1;
    margin: 0 8px 0;
    display: inline-block;
    line-height: 22px;
    vertical-align: middle;
  }

  &.active {
    color: #e03f19;
  }
`;

export const SubTabs = ({
  active,
  items,
  onClick,
}: {
  active: number;
  items: { label: string; value: ItemValue }[];
  onClick?: (index: number, value: ItemValue) => void;
}) => {
  const handleClick = (index: number, value: ItemValue) => () => {
    onClick?.(index, value);
  };

  return (
    <Tabs>
      {items?.map(({ label, value }, index) => (
        <Tab
          key={label}
          className={cx({ active: active === index })}
          onClick={handleClick(index, value)}
        >
          {label}
        </Tab>
      ))}
    </Tabs>
  );
};

export default SubTabs;
