import {
  DELETEMyCollection,
  GETMyCollection,
  GETPortfolios,
  GETRankingList,
  GETTagsFromNewsBySearch,
  POSTMyCollection,
  PUTMyCollection
} from '@fe-news/api/my-track';

// type
import type { Response } from '@fe-news/constants/api';
import type {
  ReturnMyCollectionType,
  ReturnTagsBySearchType,
  ReturnRankingListType,
  ReturnPortfoliosType
} from '@fe-news/constants/my-track/type';
import type {
  GETTagsFromNewsBySearchType,
  GETMyCollectionType,
  UPDATEMyCollectionType,
  GETRankingListType,
  GETPortfoliosType
} from '@fe-news/api/my-track';

// 搜尋新聞標籤

export const fetchTagsFromNewsBySearch = async ({
  keyword,
  version
}: GETTagsFromNewsBySearchType): Promise<Response<ReturnTagsBySearchType>> => {
  try {
    const response = await GETTagsFromNewsBySearch({ keyword, version });
    return { data: response, isError: false, message: '成功' };
  } catch (error) {
    console.error(`[ACTION ERROR]: GETTagsFromNewsBySearch ${keyword}`, error);
    return { isError: true, message: `[ACTION ERROR]: fetchTagsFromNewsBySearch ${keyword} : ${error}` };
  }
};

// 取得 熱門追蹤/新聞收藏/tag標籤 收藏排行

export const fetchRankingList = async ({
  type,
  time,
  version
}: GETRankingListType): Promise<Response<ReturnRankingListType>> => {
  try {
    const response = await GETRankingList({ type, time, version });
    return { data: response, isError: false, message: '成功' };
  } catch (error) {
    console.error(`[ACTION ERROR]: GETRankingList ${type}`, error);
    return { isError: true, message: `[ACTION ERROR]: fetchRankingList ${type} : ${error}` };
  }
};

// 取得 我的收藏列表

export const fetchMyCollection = async ({
  productType,
  groupId,
  version
}: GETMyCollectionType): Promise<Response<ReturnMyCollectionType>> => {
  try {
    const response = await GETMyCollection({ productType, groupId, version });
    return { data: response, isError: false, message: '成功' };
  } catch (error) {
    console.error(`[ACTION ERROR]: GETMyCollection ${productType}`, error);
    return { isError: true, message: `[ACTION ERROR]: fetchMyCollection ${productType} : ${error}` };
  }
};

// 更新我的收藏

export const putMyCollection = async ({
  productType,
  version,
  data
}: UPDATEMyCollectionType): Promise<Response<ReturnMyCollectionType>> => {
  try {
    const response = await PUTMyCollection({ productType, version, data });

    return { data: response, isError: false, message: response.message };
  } catch (error) {
    console.error(`[ACTION ERROR]: putMyCollection ${productType}`, error);
    return { isError: true, message: `[ACTION ERROR]: putMyCollection ${productType} : ${error}` };
  }
};

// 新增新聞收藏

export const postMyCollection = async ({
  productType,
  version,
  data
}: UPDATEMyCollectionType): Promise<Response<ReturnMyCollectionType>> => {
  try {
    const response = await POSTMyCollection({ productType, version, data });

    return { data: response, isError: false, message: response.message };
  } catch (error) {
    console.error(`[ACTION ERROR]: postMyCollection ${productType}`, error);

    if (error instanceof Error) {
      const errorData = JSON.parse(error.message);

      return { isError: true, message: JSON.parse(errorData.message).message };
    }
    return { isError: true, message: 'Unknown error' };
  }
};

// 刪除我的收藏

export const deleteMyCollection = async ({
  productType,
  version,
  data
}: UPDATEMyCollectionType): Promise<Response<ReturnMyCollectionType>> => {
  try {
    const response = await DELETEMyCollection({ productType, version, data });

    return { data: response, isError: false, message: response.message };
  } catch (error) {
    console.error(`[ACTION ERROR]: deleteMyCollection ${productType}`, error);
    return { isError: true, message: `[ACTION ERROR]: deleteMyCollection ${productType} : ${error}` };
  }
};

// 取得自選列表

export const fetchPortfolios = async ({
  symbols,
  version,
  page,
  limit,
  startAtDate,
  endAtDate,
  type,
  title
}: GETPortfoliosType): Promise<Response<ReturnPortfoliosType>> => {
  try {
    const response = await GETPortfolios({ symbols, version, page, limit, startAtDate, endAtDate, type, title });
    return { data: response, isError: false, message: '成功' };
  } catch (error) {
    console.error(`[ACTION ERROR]: GETPortfolios ${symbols}`, error);
    return { isError: true, message: `[ACTION ERROR]: fetchPortfolios ${symbols} : ${error}` };
  }
};
