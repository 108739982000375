'use client';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '@linaria/react';
import Image from 'next/image';
import Link from 'next/link';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';

const PopupTip = styled.div`
  position: fixed;
  z-index: 100;
  padding: 15px 16px 12px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(81, 92, 105, 0.2);
  border: solid 1px #f0f9eb;
  background-color: #f0f9eb;

  animation:
    fade-in 2s ease-in-out,
    fade-out 2s ease-in-out 6s forwards;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    bottom: 0px;
    right: 8px;
    left: 8px;
  }

  @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
    top: 98px;
    right: 24px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`;

const PopupTipContainer = styled.div`
  display: flex;
  column-gap: 14px;
  letter-spacing: 0.5px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #67c23a;
    margin-bottom: 9px;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
    color: #383838;
  }

  a {
    color: #0073ff;
  }
`;

type SuccessFollowPopUpProps = {
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  isCollection?: boolean;
};

const SuccessFollowPopUp: React.FC<SuccessFollowPopUpProps> = ({ setIsSuccess, isCollection = false }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const timer = setTimeout(() => {
      setIsSuccess(false);
    }, 10 * 1000);

    return () => {
      clearTimeout(timer);
      setMounted(false);
    };
  }, [setIsSuccess]);

  if (!mounted) return null;

  return createPortal(
    <PopupTip role='alert' aria-live='polite'>
      <PopupTipContainer>
        <Image
          src='/assets/icons/alert-success-green.svg'
          alt='success-icon'
          width={24}
          height={24}
          aria-hidden='true'
        />
        <div>
          <h3>{isCollection ? '成功收藏' : '成功追蹤'}</h3>
          <p>
            前往『
            <Link href='/member/mytag/all' aria-label='前往我的新聞頁面'>
              我的新聞
            </Link>
            』查看！
          </p>
          <p>
            或前往『
            <Link href='/member/rankinglist' aria-label='前往熱門排行頁面'>
              熱門排行
            </Link>
            』，查看最多人{isCollection ? '收藏的新聞' : '追蹤的熱門時事'}！
          </p>
        </div>
      </PopupTipContainer>
    </PopupTip>,
    document.body
  );
};

export default SuccessFollowPopUp;
