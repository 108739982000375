import { apiClient, removeUndefinedKeys, memberWithAuthClient } from './clients';
import { RankingProductType } from '@fe-news/constants/my-track/type';

// type
import type {
  ResponseTagsBySearchType,
  ReturnTagsBySearchType,
  MyCollectionProductType,
  ResponseMyCollectionType,
  ReturnMyCollectionType,
  ResponseRankingListType,
  ReturnRankingListType,
  ResponsePortfoliosType,
  ReturnPortfoliosType
} from '@fe-news/constants/my-track/type';

function combineUrlWithParams(url: string, params: Record<string, string | number>): string {
  return `${url}?${Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}

/**
 * /api/v1/search/tags?keyword=hello
 * @export 搜尋新聞中有提及的 tag
 * @param {string} keyword 關鍵字
 * @returns {Promise<PopTopicDataWithPage>}
 */

export type GETTagsFromNewsBySearchType = {
  keyword: string;
  version?: string;
};

export const GETTagsFromNewsBySearch = async ({
  keyword,
  version = 'v1'
}: GETTagsFromNewsBySearchType): Promise<ReturnTagsBySearchType> => {
  const url = `/media/api/${version}/search/tags`;

  const params = {
    keyword
  };

  try {
    const data = (await apiClient.get(url, removeUndefinedKeys(params), {
      next: { revalidate: 60 }
    })) as ResponseTagsBySearchType;

    const { items } = data;

    return { tags: items.map(item => ({ key: item.key, docCount: item.doc_count })) };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        tags: []
      };
    }

    console.error(`[API Error]: GETTagsFromNewsBySearch ${keyword}`, error);
    throw new Error(`[API Error]: GETTagsFromNewsBySearch ${keyword} : ${error}`);
  }
};

/**
 * /member-always-right/api/v1/mycollections/ranking
 * @export 熱門追蹤/新聞收藏/tag標籤 收藏排行
 * @param {type} RankingProductType 產品別
 * @time {number} time 時間戳
 * @returns {Promise<ReturnRankingListType>}
 */

export type GETRankingListType = {
  type: RankingProductType;
  time?: number;
  version?: string;
};

export const GETRankingList = async ({
  type,
  time,
  version = 'v1'
}: GETRankingListType): Promise<ReturnRankingListType> => {
  const url = `/member-always-right/api/${version}/mycollections/ranking`;

  const params: {
    type: RankingProductType;
    ts?: number;
  } = { type: type, ts: time };

  try {
    if (type === RankingProductType.POPTOPIC || type === RankingProductType.NEWS) {
      const data = (await memberWithAuthClient.get(
        combineUrlWithParams(url, params)
      )) as ResponseRankingListType<RankingProductType.POPTOPIC>;

      return {
        items: data.items.map(item => ({ productId: item.product_id, cnt: item.cnt })),
        message: data.message,
        statusCode: data.status_code
      };
    }

    const data = (await memberWithAuthClient.get(
      combineUrlWithParams(url, params)
    )) as ResponseRankingListType<RankingProductType.TAGS>;

    return {
      items: data.items.map(item => ({ productId: item.tag, cnt: item.cnt })),
      message: data.message,
      statusCode: data.status_code
    };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        items: [],
        message: 'error',
        statusCode: 404
      };
    }

    console.error(`[API Error]: GETRankingList ${type}`, error);
    throw new Error(`[API Error]: GETRankingList ${type} : ${error}`);
  }
};

/**
 * /member-always-right/api/v1/mycollections
 * @export 我的收藏列表
 * @param {product_type} MyCollectionProductType 產品別
 * @returns {Promise<ReturnMyCollectionType>}
 */

export type GETMyCollectionType = {
  productType?: MyCollectionProductType;
  groupId?: number;
  version?: string;
};

export const GETMyCollection = async ({
  productType,
  groupId,
  version = 'v1'
}: GETMyCollectionType): Promise<ReturnMyCollectionType> => {
  const url = `/member-always-right/api/${version}/mycollections`;

  const params: {
    type?: MyCollectionProductType;
    group?: number;
  } = { type: productType, group: groupId };

  try {
    const data = (await memberWithAuthClient.get(combineUrlWithParams(url, params))) as ResponseMyCollectionType;

    return {
      items: data.items.data.map(item => ({
        identityId: item.identity_id,
        productType: item.product_type,
        productId: item.product_id,
        sort: item.sort
      })),
      count: data.items.count
    };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        items: [],
        count: 0
      };
    }

    console.error(`[API Error]: GETMyCollection ${productType}`, error);
    throw new Error(`[API Error]: GETMyCollection ${productType} : ${error}`);
  }
};

export type UPDATEMyCollectionType = Omit<GETMyCollectionType, 'groupId'> & { data: string[] };

/**
 * /member-always-right/api/v1/mycollections
 * @export 新增新聞收藏
 * @param {product_type} MyCollectionProductType 產品別
 * @returns {Promise<ReturnMyCollectionType>}
 */

export const POSTMyCollection = async ({
  productType,
  version = 'v1',
  data
}: UPDATEMyCollectionType): Promise<ReturnMyCollectionType & { message: string }> => {
  const url = `/member-always-right/api/${version}/mycollections`;

  const body = {
    product_type: productType,
    data
  };

  try {
    const data = (await memberWithAuthClient.post(url, body)) as ResponseMyCollectionType;

    if (data.status_code === 4009) {
      throw new TypeError(JSON.stringify({ message: data.message }));
    }

    return {
      items: [],
      count: 0,
      message: data.message
    };
  } catch (error) {
    console.error(`[API Error]: POSTMyCollection ${productType}`, error);

    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      throw new TypeError(JSON.stringify('失敗'));
    }

    if (error instanceof Error) {
      throw new TypeError(JSON.stringify({ message: error.message }));
    }

    throw new TypeError(JSON.stringify({ message: 'Unknown error' }));
  }
};

/**
 * /member-always-right/api/v1/mycollections
 * @export 更新我的收藏
 * @param {product_type} MyCollectionProductType 產品別
 * @returns {Promise<ReturnMyCollectionType>}
 */

export const PUTMyCollection = async ({
  productType,
  version = 'v1',
  data
}: UPDATEMyCollectionType): Promise<ReturnMyCollectionType & { message: string }> => {
  const url = `/member-always-right/api/${version}/mycollections`;

  const body = {
    product_type: productType,
    data
  };

  try {
    const data = (await memberWithAuthClient.put(url, body)) as ResponseMyCollectionType;

    return {
      items: [],
      count: 0,
      message: data.message
    };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        items: [],
        count: 0,
        message: '失敗'
      };
    }

    console.error(`[API Error]: PUTMyCollection ${productType}`, error);
    throw new Error(`[API Error]: PUTMyCollection ${productType} : ${error}`);
  }
};

/**
 * /member-always-right/api/v1/mycollections
 * @export 刪除我的收藏
 * @param {product_type} MyCollectionProductType 產品別
 * @returns {Promise<ReturnMyCollectionType>}
 */

export const DELETEMyCollection = async ({
  productType,
  version = 'v1',
  data
}: UPDATEMyCollectionType): Promise<ReturnMyCollectionType & { message: string }> => {
  const url = `/member-always-right/api/${version}/mycollections`;

  const body = {
    product_type: productType,
    data
  };

  try {
    const data = (await memberWithAuthClient.delete(url, body)) as ResponseMyCollectionType;

    return {
      items: [],
      count: 0,
      message: data.message
    };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        items: [],
        count: 0,
        message: '失敗'
      };
    }

    console.error(`[API Error]: DELETEMyCollection ${productType}`, error);
    throw new Error(`[API Error]: DELETEMyCollection ${productType} : ${error}`);
  }
};

/**
 * /media/api/v1/newslist/{symbols}/symbolNews
 * @export 取得自選列表
 * @param {string} symbols 產品別
 * @returns {Promise<ReturnPortfoliosType>}
 */

export type GETPortfoliosType = {
  symbols: string;
  version?: string;
  page?: number;
  limit?: number;
  startAtDate?: number;
  endAtDate?: number;
  type?: number;
  title?: string;
};

export const GETPortfolios = async ({
  symbols,
  version = 'v1',
  page = 1,
  limit = 10,
  startAtDate,
  endAtDate,
  type,
  title
}: GETPortfoliosType): Promise<ReturnPortfoliosType> => {
  const url = `/media/api/${version}/newslist/${symbols}/symbolNews`;

  const params = {
    limit,
    page,
    startAt: startAtDate,
    endAt: endAtDate,
    type: type === 0 ? undefined : type,
    title
  };

  try {
    const data = (await apiClient.get(url, removeUndefinedKeys(params), {
      next: { revalidate: 60 }
    })) as ResponsePortfoliosType;

    return {
      data: data.items.data,
      total: data.items.total,
      perPage: data.items.per_page,
      currentPage: data.items.current_page,
      lastPage: data.items.last_page,
      nextPageUrl: data.items.next_page_url ?? '',
      prevPageUrl: data.items.prev_page_url ?? '',
      from: data.items.from,
      to: data.items.to
    };
  } catch (error) {
    if (error instanceof Error && JSON.parse(error.message).code === 404) {
      return {
        data: [],
        total: 0,
        perPage: 0,
        currentPage: 0,
        lastPage: 0,
        nextPageUrl: '',
        prevPageUrl: '',
        from: 0,
        to: 0
      };
    }

    console.error(`[API Error]: GETPortfolios ${symbols}`, error);
    throw new Error(`[API Error]: GETPortfolios ${symbols} : ${error}`);
  }
};
