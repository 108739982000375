'use client';
import { CustomEventName, GTMConstants, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import * as Tabs from '@fe-common-ui/Tabs';
import { NewsCategory } from '@fe-news/constants/categories';
import { DEFAULT_IS_HEADLINE_CATEGORIES} from '@fe-news/utils/category';
import { styled } from '@linaria/react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

const HeadlineTab = styled.span<{ active?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? '#e03f19' : '#515c69')};

  &::before {
    content: '';
    background-image: url('/assets/icons/newsCategory/headline.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    filter: ${({ active }) =>
      active
        ? 'invert(32%) sepia(68%) saturate(1383%) hue-rotate(345deg) brightness(100%) contrast(102%)'
        : 'invert(35%) sepia(10%) saturate(915%) hue-rotate(172deg) brightness(91%) contrast(80%)'};
  }
`;

const AllTab = styled.span<{ active?: boolean }>`
  color: ${({ active }) => (active ? '#e03f19' : '#515c69')};
`;

enum Tab {
  HEADLINE,
  ALL
}

const TAB_LABELS = ['頭條', '全部'];

const HeadlineFilter = ({ category }: { category: NewsCategory }) => {
  const router = useRouter();
  // 只有在DEFAULT_IS_HEADLINE_CATEGORIES中,預設值才為頭條,其他預設為全部
  const isHeadline = DEFAULT_IS_HEADLINE_CATEGORIES.includes(category);
  const defaultCurrent = isHeadline ? Tab.HEADLINE : Tab.ALL;
  const [current, setCurrent] = useState<number>(defaultCurrent);

  // 分類API query: isCategoryHeadline=1 (頭條)
  const getNewsFilterIsHeadline = () => {
    return String(1 - current);
  };

  // 將頭條/全部按鈕值儲存在react-query中,在其他元件透過同一組key拿取值
  const { refetch } = useQuery({
    queryKey: ['news', 'filter', 'isCategoryHeadline'],
    queryFn: getNewsFilterIsHeadline,
    notifyOnChangeProps: ['data'],
    initialData: String(current)
  });

  useEffect(() => {
    refetch();
  }, [refetch, current]);

  const onClick = (tab: number) => {
    /**
     * 在/news/cat/headline與/news/cat/all, (頭條/全部)按鈕會沒有作用, 因為isCategoryHeadline參數沒有作用
     * 所以在headline按下全部時,導轉至/news/cat/all
     * 在all按下頭條導轉至/news/cat/headline
     */

    if (category === NewsCategory.HEADLINE && tab === Tab.ALL) {
      router.push('/news/cat/all');
    } else if (category === NewsCategory.ALL && tab === Tab.HEADLINE) {
      router.push('/news/cat/headline');
    }

    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_FILTER,
      [GTMEventKey.SECTION]: `${GTMConstants.SECTION_PLACEHOLDER}`,
      [GTMEventKey.CLICK_ITEM]: TAB_LABELS[tab]
    });
  };

  const onClickTab = (n: number) => {
    setCurrent(n);
  };

  return (
    <Tabs.Switch active={current} onClick={onClickTab}>
      <HeadlineTab active={current === Tab.HEADLINE} onClick={() => onClick(Tab.HEADLINE)}>
        {TAB_LABELS[Tab.HEADLINE]}
      </HeadlineTab>
      <AllTab active={current === Tab.ALL} onClick={() => onClick(Tab.ALL)}>
        {TAB_LABELS[Tab.ALL]}
      </AllTab>
    </Tabs.Switch>
  );
};

export default HeadlineFilter;
